<template>
  <v-container fluid class="pa-0" grid-list-lg>
    <v-card  color="yellow darken-2" class="elevation-10">
      <v-card-title>
        <v-flex xs12 sm4> Purchase </v-flex>
        <v-spacer></v-spacer>
        <v-flex xs12 sm4>
          <v-text-field v-model="search" outlined prepend-inner-icon="search" rounded dense label="Search" single-line
            hide-details clearable></v-text-field>
        </v-flex>
        <!-- <v-flex class="shrink">
          <v-btn @click="print" class="ml-1" icon>
            <v-icon>print</v-icon>
            </v-btn>
        </v-flex> -->
      </v-card-title>
      <v-data-table :headers="headers" :items="purchase" :search="search" :loading="loading"  :sort-by="sortBy"
        :page.sync="page" :items-per-page="itemsPerPage">
        <template v-slot:item="{ item }">
          <tr class="pointer">
            <!-- <td @click="viewPurchase(item.PurchaseId)" class="text-left">
              <span>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon class="mr-2" small v-on="on"
                      >mdi-open-in-new</v-icon
                    >
                    <span>{{ $moment(item.updatedAt).format("MM/DD/YYYY") }}</span>
                  </template>
<span>View Full Purchase Order</span>
</v-tooltip>
</span>
</td> -->
            <td class="text-left text-capitalize">
              {{ item.updatedAt | moment("L") }}
            </td>
            <td class="text-left text-capitalize">
              <v-btn @click="setOpenSerialSearch(item)" text icon small color="primary">
                <v-icon color="green">mdi-feature-search-outline</v-icon>
              </v-btn>
              <span> {{ item.imeiNumber }}</span>
            </td>
            <td>
              {{ textCapitalize(item.status) }}
            </td>
            <td class="text-right">
              {{ item.cost | currency }}
            </td>
          </tr>
        </template>
        <v-alert slot="no-results" :value="true" color="error" icon="warning">Your search for "{{ search }}" found no
          results.</v-alert>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import purchaseService from "@/modules/Inventory/Purchase/service.js";
import { mapActions, mapGetters } from "vuex";

export default {
  props: ["ProductId","status","singleStatus"],
  name: "purchase-detail-list",
  data() {
    return {
      headers: [
        {
          text: "Created At",
          value: "date",
          sortable: true,
        },
        
        {
          text: "IMEI Number",
          value: "imeiNumber",
          sortable: true,
        },
        {
          text: "Status",
          value: "status",
          sortable: true,
        },
        {
          text: "Cost Price",
          align: "right",
          value: "cost",
          sortable: true,
        },
      ],
      purchase: [],
      search: null,
      loading: true,
      page: 1,
      sortBy: "createdAt",
      pageCount: 0,
      itemsPerPage: 10,
    };
  },
  created() {
    this.getList();
  },
  computed: {
    ...mapGetters("global", ["currentUser", "checkRightStatus"]),
  },
  methods: {
    ...mapActions("layout", ["setOpenSerialSearch", "setOpenRestockSerial"]),
    textCapitalize(val) {
      const capitalizedText = val.charAt(0).toUpperCase() + val.slice(1);
      return capitalizedText
    },
    getList() {
      this.loading = true;
      const where = {
        status: this.status,
        singleStatus: this.singleStatus,
        ProductId: this.ProductId,
      };
      // console.log('where', where)
      return purchaseService.getSerialVault(where).then((response) => {
        // console.log('getSerialVault', response)
        this.loading = false;
        this.purchase = response.data;
        return response;
      });
    },
    print() {
      const routeData = this.$router.resolve({
        path: `/global/print/availableIMEI/${this.ProductId}/view`,
      });
      window.open(routeData.href, "newwindow", "width=800, height=950");
    },
    viewPurchase(purchase) {
      // console.log("ddfd", purchase);
      const routeData = this.$router.resolve({
        path: `/global/purchase/${purchase}/view`,
      });
      window.open(routeData.href, "newwindow", "width=800, height=950");
    },
  },
};
</script>
