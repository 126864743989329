var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pa-0",attrs:{"fluid":"","grid-list-lg":""}},[_c('Edit',{attrs:{"editDialog":_vm.editDialog,"id":_vm.selectedProduct},on:{"close":function($event){_vm.editDialog = !_vm.editDialog},"submit":_vm.productEditSubmit}}),_c('v-card',{staticClass:"elevation-10",attrs:{"color":"yellow darken-3"}},[_c('v-card-title',[_c('v-flex',{attrs:{"xs12":"","sm4":""}},[_vm._v(" Products ")]),_c('v-spacer'),_c('v-flex',{attrs:{"xs12":"","sm4":""}},[_c('v-text-field',{attrs:{"outlined":"","prepend-inner-icon":"search","rounded":"","dense":"","label":"Search","single-line":"","hide-details":"","clearable":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.products,"search":_vm.search,"loading":_vm.loading,"sort-by":_vm.sortBy,"page":_vm.page,"single-expand":_vm.singleExpand,"expanded":_vm.expanded,"show-expand":"","items-per-page":_vm.itemsPerPage},on:{"update:page":function($event){_vm.page=$event},"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
var isExpanded = ref.isExpanded;
var expand = ref.expand;
return [_c('tr',{staticClass:"pointer"},[_c('td',{staticClass:"text-left"},[(item.balance && item.trackSerialNumber && !isExpanded)?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return expand(true)}}},[_c('v-icon',[_vm._v("mdi-chevron-down")])],1):_vm._e(),(item.balance && item.trackSerialNumber && isExpanded)?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return expand(false)}}},[_c('v-icon',[_vm._v("mdi-chevron-up")])],1):_vm._e()],1),_c('td',{staticClass:"text-left"},[_vm._v(" "+_vm._s(item.name)+" ")]),_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(item.balance)+" ")]),_c('td',{staticClass:"text-right"},[_c('span',{staticClass:"mr-2"},[_vm._v(_vm._s(_vm._f("currency")(item.price)))]),(item.trackSerialNumber)?_c('span',{staticClass:"mr-2"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"small":""}},on),[_vm._v("mdi-barcode-scan")])]}}],null,true)},[_c('span',[_vm._v("Track with IMEI number")])])],1):_vm._e(),(!item.trackSerialNumber && item.trackInventory)?_c('span',{staticClass:"mr-2"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"small":""}},on),[_vm._v("mdi-package-variant-closed")])]}}],null,true)},[_c('span',[_vm._v("Track inventory on this product")])])],1):_vm._e()]),_c('td',{staticClass:"justify-end align-center text-right"},[_c('v-menu',{attrs:{"bottom":"","left":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""}},on),[_c('v-icon',[_vm._v("more_vert")])],1)]}}],null,true)},[_c('v-list',{staticClass:"menu-button"},[_c('v-list-item',{on:{"click":function($event){return _vm.editProduct(item)}}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("buttons.edit")))])],1),_c('v-list-item',{on:{"click":function($event){return _vm.deleteProduct(item)}}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("buttons.delete")))])],1)],1)],1)],1)])]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{staticClass:"pa-0",attrs:{"colspan":headers.length}},[_c('v-card',{attrs:{"color":"#F3F8FC","flat":"","tile":""}},[_c('v-card-text',{staticClass:"title"},[_c('PurchaseDetialList',{attrs:{"ProductId":item.id}})],1)],1)],1)]}}])},[_c('v-alert',{attrs:{"slot":"no-results","value":true,"color":"error","icon":"warning"},slot:"no-results"},[_vm._v("Your search for \""+_vm._s(_vm.search)+"\" found no results.")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }