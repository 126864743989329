<template>
  <v-avatar tile class="mt-2 mb-2 mr-2">
    <v-img v-if="item.imageUrl" :src="item.imageUrl"> </v-img>
    <v-tooltip v-else bottom>
      <template v-slot:activator="{ on }">
        <v-btn :loading="loading" icon fab v-on="on" @click="fatchImage(item)">
          <v-icon>mdi-file-image-outline</v-icon>
        </v-btn>
        <!-- <v-btn color="primary" dark v-on="on">Button</v-btn> -->
      </template>
      <span>Fatch Image</span>
    </v-tooltip>
  </v-avatar>
</template>

<script>
import productService from "@/modules/Product/service.js";
import categoryService from "@/modules/Product/Category/service.js";

export default {
  props: ["item", "type"],
  data() {
    return {
      loading: false,
      imageResult: null,
      imageUrl: null,
    };
  },
  methods: {
    getimage(name) {
      return productService
        .getGooglePic({ name: name })
        .then((response) => {
          return response;
        })
        .catch((error) => {
          console.log("error", error);
        });
    },
    updateProduct(item, imageUrl) {
      return productService
        .update(item.id, { imageUrl })
        .then((response) => {
          return response;
        })
        .catch((error) => {
          console.log("error", error);
        });
    },
    updateCategory(item, imageUrl) {
      return categoryService
        .update(item.id, { imageUrl })
        .then((response) => {
          return response;
        })
        .catch((error) => {
          console.log("error", error);
        });
    },
    fatchImage() {
      this.loading = true;
      this.getimage(this.item.name)
        .then((response) => {
          if (response.data) {
            this.imageResult = response.data;
            this.imageUrl = response.data.imgUrl1;
            this.item.imageUrl = this.imageUrl;
            if (this.type === "product") {
              this.updateProduct(this.item, this.imageUrl);
              this.loading = false;
            }
            if (this.type === "category") {
              this.updateCategory(this.item, this.imageUrl);
              this.loading = false;
            }
          }
        })
        .catch((error) => {
          console.log("error", error);
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped></style>
