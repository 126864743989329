<template>
  <v-container class="pa-0" fluid grid-list-lg>
    <Edit :editDialog="editDialog" @close="editDialog = !editDialog" @submit="productEditSubmit" :id="selectedProduct">
    </Edit>
    <v-card color="yellow darken-3" class="elevation-10">
      <v-card-title>
        <v-flex xs12 sm4>
          Products
        </v-flex>
        <v-spacer></v-spacer>
        <v-flex xs12 sm4>
          <v-text-field v-model="search" outlined prepend-inner-icon="search" rounded dense label="Search" single-line
            hide-details clearable></v-text-field>
        </v-flex>
      </v-card-title>
      <v-data-table  :headers="headers" :items="products" :search="search" :loading="loading" :sort-by="sortBy"
        :page.sync="page" :single-expand="singleExpand" :expanded.sync="expanded" show-expand
        :items-per-page="itemsPerPage">
        <template v-slot:item="{ item, isExpanded, expand }">
          <tr class="pointer">
            <td class="text-left">
              <v-btn @click="expand(true)" icon
                v-if="item.balance && item.trackSerialNumber && !isExpanded"><v-icon>mdi-chevron-down</v-icon></v-btn>
              <v-btn @click="expand(false)" icon
                v-if="item.balance && item.trackSerialNumber && isExpanded"><v-icon>mdi-chevron-up</v-icon></v-btn>
            </td>
            <td class="text-left">
              {{ item.name }}
            </td>
            <td class="text-center">
              {{ item.balance }}
            </td>
            <td class="text-right">
              <span class="mr-2">{{ item.price | currency }}</span>

              <span class="mr-2" v-if="item.trackSerialNumber">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon small v-on="on">mdi-barcode-scan</v-icon>
                  </template>
                  <span>Track with IMEI number</span>
                </v-tooltip>
              </span>
              <span class="mr-2" v-if="!item.trackSerialNumber && item.trackInventory">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon small v-on="on">mdi-package-variant-closed</v-icon>
                  </template>
                  <span>Track inventory on this product</span>
                </v-tooltip>
              </span>
            </td>

            <td class="justify-end align-center text-right">
              <v-menu bottom left offset-y>
                <template v-slot:activator="{ on }">
                  <v-btn v-on="on" icon>
                    <v-icon>more_vert</v-icon>
                  </v-btn>
                </template>
                <v-list class="menu-button">
                  <v-list-item @click="editProduct(item)">
                    <v-list-item-title>{{
                      $t("buttons.edit")
                      }}</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="deleteProduct(item)">
                    <v-list-item-title>{{
                      $t("buttons.delete")
                      }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </td>
          </tr>
        </template>
        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length" class="pa-0">
            <v-card color="#F3F8FC" flat tile>
              <v-card-text class="title">
                <PurchaseDetialList :ProductId="item.id"></PurchaseDetialList>
              </v-card-text>
            </v-card>
          </td>
        </template>
        <v-alert slot="no-results" :value="true" color="error" icon="warning">Your search for "{{ search }}" found no
          results.</v-alert>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import productService from "../service";
import PurchaseDetialList from "@/modules/Inventory/Purchase/components/PurchaseDetailList";
const Edit = () => import("./Edit");

export default {
  name: "phone-list",
  props: ["CategoryId", "ProductId"],
  data() {
    return {
      expanded: [],
      singleExpand: true,
      editDialog: false,
      products: [],
      selectedProduct: null,
      headers: [
        { text: "", value: "data-table-expand" },
        {
          text: this.$t("labels.name"),
          align: "left",
          value: "name",
          sortable: true,
        },

        {
          text: this.$t("labels.stock"),
          value: "balance",
          align: "center",
          sortable: true,
        },
        {
          text: this.$t("labels.amount"),
          value: "price",
          align: "right",
          sortable: true,
        },
        {
          text: "",
          value: "action",
          sortable: false,
        },
      ],
      search: null,
      loading: true,
      page: 1,
      sortBy: "balance",
      pageCount: 0,
      itemsPerPage: 10,
    };
  },
  components: {
    Edit,
    PurchaseDetialList,
  },
  created() {
    this.getList();
  },
  methods: {
    productEditSubmit() {
      this.editDialog = false;
      this.getList();
    },
    getList() {
      this.loading = true;


      if (this.CategoryId) {
        return productService.getAllCaregoryProduct({ CategoryId: this.CategoryId }).then((response) => {
          this.loading = false;
          this.products = response.data;
          return response;
        });

      }
      if (this.ProductId) {
        return productService.getAll({ ChildProductId: this.ProductId }).then((response) => {
          this.loading = false;
          this.products = response.data;
          return response;
        });
      }


    },
    editProduct(product) {
      this.selectedProduct = product.id
      this.editDialog = !this.editDialog;
    },
    deleteProduct(product) {
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.value) {
          productService.delete(product.id).then((response) => {
            if (response.data) {
              this.$swal("Deleted!", "Product has been deleted.", "success");
              this.getList();
            }
          });
        }
      });
    },
  },
};
</script>
